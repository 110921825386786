<template>
  <b-row>
    <b-col
      cols="12"
      md="4"
    >
      <b-card title="Form Mesajı">
        <call-form :data-item="dataItem" />
      </b-card>
    </b-col>
    <b-col>
      <b-card
        v-if="dataItem.message"
        title="Talep İçeriği"
      >
        {{ dataItem.message }}
      </b-card>
      <alert-box
        v-if="dataItem.interview_number"
        alert-text="Görüntülemek istediğiniz web talebi kapatılmış."
        alert-variant="danger"
        :route-url="'/interviews/view/' + dataItem.interview_number"
      />
      <alert-box
        v-else-if="userData.id !== dataItem.id_com_user"
        alert-text="Web talebi size atanmamış. İşlem yapmaya yetkiniz bulunmamaktadır."
        alert-variant="warning"
      />
      <validation-observer
        v-else
        ref="simpleRules"
      >
        <b-card title="Görüşme Notu">
          <status-code />
          <interview-content />
        </b-card>
        <save-button
          :is-disabled="false"
          :save-action="saveData"
        />
      </validation-observer>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BCard,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import CallForm from '@/views/Webforms/components/Callform.vue'
import AlertBox from '@/views/Webforms/components/AlertBox.vue'
import InterviewContent from '@/views/Webforms/components/elements/Callform/InterviewContent.vue'
import StatusCode from '@/views/Webforms/components/elements/Callform/StatusCode.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'

export default {
  name: 'ViewForm',
  components: {
    BRow,
    BCol,
    BCard,
    CallForm,
    InterviewContent,
    StatusCode,
    AlertBox,
    SaveButton,
    ValidationObserver,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['webformCallform/dataItem']
    },
    saveStatus() {
      return this.$store.getters['webformCallform/dataSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config, val.id)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config, val.id)
      }
    },
  },
  created() {
    this.getData()
    localize('tr')
  },
  methods: {
    sweetAlert(config, interviewNumber) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      }).then(response => {
        if (response.isConfirmed) {
          this.$router.push(`/interviews/view/${interviewNumber}`)
        }
      })
    },
    getData() {
      this.$store.dispatch('webformCallform/getDataItem', this.$route.params.id)
    },
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('webformCallform/saveData', this.dataItem)
        }
      })
    },
  },
}
</script>
